import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import get from 'lodash-es/get';

import linkResolver from '../../../../utils/linkResolver';
import { TAG_PORTAL_CHILD_PAGE } from '../../../../utils/constants';

const PanelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: column;
  }
`;

const PanelLink = styled(Link)`
  font-size: 1rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${prop('theme.colors.black')};
  border: 1px solid ${prop('theme.colors.carouselBorderGrey')};
  border-radius: 16px;
  height: 56px;
  background-color: ${prop('theme.colors.white')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  margin-right: 24px;
  width: 100%;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${prop('theme.colors.black')};
    text-decoration: none;
    background-color: ${prop('theme.colors.backgroundGreyLighter')};
  }

  @media (max-width: ${prop('theme.breakpoints.xl')}) and (min-width: ${prop(
      'theme.breakpoints.lg'
    )}) {
    padding: 12px 24px;
    height: initial;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin: 16px 0 0;
    min-width: max-content;
  }
`;

const propTypes = { data: PropTypes.object, pageUid: PropTypes.string };

const defaultProps = { data: {}, pageUid: '' };

function PanelLinks({ data, pageUid }) {
  const panels = data.items;

  if (!panels) {
    return null;
  }

  return (
    <PanelsContainer>
      {panels.map((panel, index) => {
        const linkTag = get(panel, 'panel_link.tags.[0]');

        return (
          <PanelLink
            key={`panel-link-${index}`}
            to={linkResolver({
              ...panel.panel_link,
              parentUid: linkTag === TAG_PORTAL_CHILD_PAGE ? pageUid : ''
            })}
          >
            {panel.link_text}
          </PanelLink>
        );
      })}
    </PanelsContainer>
  );
}

PanelLinks.propTypes = propTypes;
PanelLinks.defaultProps = defaultProps;

export default PanelLinks;
